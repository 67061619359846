@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css?family=Epilogue');
:root{
  --marginleftlarge:15%;
  --marginleftsmall: auto;
  --maringrightsmall:auto;
}
body {
  margin: 0;
  background-color: #fbf3e6 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
body::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body { font-family: sans-serif; }


/*  grid*/
.grid {
  width: 100%;
}

/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* grid-item*/
.grid-item {
  width: 100%;
}
.grid-item img {
  display: block;
  max-width: 100%;
  padding: 10px;
}

#navbarScroll::-webkit-scrollbar{
  display: none;
}

.splashArt{
  display: flex;
  justify-content: center;
}
#splash{
  width: 70%;


}
#scroll-container {
  overflow: hidden;
}

#scroll-text {
  font-family: 'Epilogue';
  font-size: 180%;
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 25s linear infinite;
  -webkit-animation: my-animation 25s linear infinite;
  animation: my-animation 25s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
/* about me page css */
.aboutPage{
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*about me text container*/
.txtSide{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/*about me picture container*/
.pictSide{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  justify-content: center;
  
}

/*about page images formatting*/
.aboutImg{
  height: 100%;
  width: 60%;
  margin: 20px;
  object-fit: contain;
}

/*header txt*/
#header{
  font-family: 'Archivo Black', sans-serif;
  font-size: 300%;
  margin-top: 40px;
  color: #3158E2;
}


#leftSprite{
  padding-left: 88px;
  height: 108px;
}

/*bulk text i.e any large paragraphs or blocks of text*/
#bulk{
  font-family: 'Epilogue';
  font-size: 180%;
  word-wrap: break-word;
  width: 85%;
  color: #393835;
}
.tag{
  font-family: 'Epilogue';
  font-size: 180%;
  margin-top: 30px;
  margin-bottom: 30px;
  text-decoration: none;
  color: #393835;
}
.tag:hover{
  color: #DFABF1;
}
/*grade year txt*/
#gradeY{
  padding-top: 0px;
  
}

.Links{
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.insta2Icon{
  width: 45px;
  margin-top: 15px;
  margin-left:0px;
  margin-right: 10px;
  margin-bottom: 15px;
}
.inprintIcon{
  width: 90px;
  margin-top: 15px;
  margin-left:30px;
  margin-right: 10px;
  margin-bottom: 15px;
}
/* The Modal (background) */

.modal-box{
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.87); /* Black w/ opacity */
}

/* Modal Content (image, caption) main container */

.modalstuff{
  width: 90%;
  height: 90%;
  max-height: 90%;
  display: flex;
  position: absolute;
  flex-direction: row;
  z-index: -1; /*sends modal content to back*/  
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);

}

/*modal img container (left container)*/
.modal-content {
  flex: 0 1 50%;
  min-height: 100%;
  max-width: 50%;
  justify-content: center;
  margin-left:var(--marginleftlarge)
}

/* Caption of Modal Image (right container)*/

.caption {
  flex: 0 1 25%;
  height: 100%;
  max-width: 100%;
  color: #ccc;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-right: 5%;
  font-family: 'Epilogue';
}
.captionList{
  list-style-type: none;
  padding-left: 0em;
  margin-left: 0;
}
.tagStyle{
  font-weight: bold;
  font-size: 30px;
  color: #DFABF1;
}
.contentStyle{
  font-weight: normal;
  font-size: 30px;
  color: #ccc;
}
/* The Close Button */
.close {
  position: absolute;
  width: 45px;
  top: 30px;
  vertical-align: top;
  right: 2%;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  opacity: 80%;
  text-decoration: none;
  cursor: pointer;
}


/*left button*/
.left {
  position: absolute;
  width:45px;
  top:50%;
  left: 20px;
  padding-left: 10px;
  border-radius: 3px 0 0 3px;
  transition: 0.3s;
}

.left:hover,
.left:focus {
  opacity: 80%;
  text-decoration: none;
  cursor: pointer;
}

/*right button*/
.right {
  position: absolute;
  border-radius: 3px 0 0 3px;
  width:45px;
  right:20px;
  top:50%;
  transition: 0.3s;
  padding-right: 10px;
}

.right:hover,
.right:focus {
  opacity: 80%;
  text-decoration: none;
  cursor: pointer;
}


/* Add Animation */
.modal-content::-webkit-scrollbar{
  display: none;

}
.modal-box::-webkit-scrollbar{
  display: none;
 
}
.caption ::-webkit-scrollbar{
  display: none;
  
 
}
::-webkit-scrollbar {
  display: none;
}
.modal-content, .caption, .right, .left, .close{

  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.iosCaptionAnimation{
  animation-name: zoom;
  animation-duration: 0.6s;
}
/*directly on the modal image tag, larger than 1204*/ 
@media only screen and (min-width: 1204px){
  .modal-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

}
/*smaller than 1204*/ 
@media only screen and (max-width: 1204px) and (min-width: 901px) {
 
  .modal-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .modalstuff{
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    position: absolute;
    flex-direction: row;
    z-index: -1; /*sends modal content to back*/  
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);

  }

/*modal img container (left container)*/
  .modal-content {
    flex: 0 1 50%;
    min-height: 100%;
    max-width: 50%;
    justify-content: center;
    margin-left: 15%;
  
  }

  .caption {
    flex: 0 1 50%;
    height: 100%;
    max-width: 100%;
    color: #ccc;
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin-right: 10%;
    font-family: 'Epilogue';
    
  }
  .tagStyle{
    font-weight: bold;
    font-size: 30px;
   
  }
  .contentStyle{
    font-weight: normal;
    font-size:  30px;
    color: #ccc;
  }
  
  #splash{
    padding-top: 20px;
    width: 100%;
  }
  #leftSprite{
    padding-left: 40px;
    height:78.75px;
  }
  #gradeY{
    padding-top: 20px;
    padding-bottom: 10px;
  }

 /* about me page css */
.aboutPage{
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*about me text container*/
.txtSide{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  
}
.Links{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 15%;
  margin-right: 15%;
}
/*about me picture container*/
.pictSide{
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  justify-content: center;
  
}
  /*about page images formatting*/
  .aboutImg{
    height: 100%;
    width: 60%;
    margin: 20px;
    object-fit: contain;
  }
  .insta2Icon{
    width: 45px;
    margin-top: 15px;
    margin-left:0px;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .inprintIcon{
    width: 90px;
    margin-top: 15px;
    margin-left:30px;
    margin-right: 10px;
    margin-bottom: 15px;
  }
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 900px) {

  .modalstuff{
    width: 100%;
    flex-direction: column;
    top:50%;
    height: fit-content;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    margin-left: auto;
    margin-right: auto;
  }
  .modal-content {
    max-width: 70%;
    flex:none;
    align-items: center;
    padding-left: 0px;
    margin-left: 15%;
    margin-right: 15%;
  }
  .modal-image{
    width: 100%;
    object-fit: contain;
  }
/* Caption of Modal Image */
  .caption {
    align-items: center;
    max-width: 100%;
    padding-top: 10px;
    color: #ccc;
    padding: 0;
    margin-top: 10px;
    margin-left:18.5%;
    margin-right:18.5%;
    font-family: 'Epilogue';
  }
  .tagStyle{
    font-weight: bold;
    font-size: 20px;

  }
  .contentStyle{
    font-weight: normal;
    font-size:  20px;
    color: #ccc;
  }
  .close {
    position: absolute;
    width: 25px;
    top: 30px;
    right: 2%;
    transition: 0.3s;
  }
  .left {
    position: absolute;
    width:25px;
    top:50%;
    left: .5em;
    border-radius: 3px 0 0 3px;
    transition: 0.3s;
    padding-left: 5px;
  }
  .right {
    position: absolute;
    border-radius: 3px 0 0 3px;
    width:25px;
    right: .5em;
    top:50%;
    padding-right: 5px;
    transition: 0.3s;
  }
  #Larrows{
    width: 40px;
    float: left;
    height: 100%;
  }
  #Rarrows{
    width: 40px;
    float: right;
    height: 100%;
  }
  .modal-image{
    width: 90%;
  }
  .captionList{
    list-style-type: none;
    padding-left: em;
    margin-left: 0;
  }
  #splash{
    padding-top: 20px;
    width: 100%;
  }
  #leftSprite{
    padding-left: 40px;
    height:78.75px;
    max-width: 100%;
  }
  #gradeY{
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .txtSide{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 80%;
    margin-left: 50px;
    margin-right: 50px;
  }
  #bulk{
    font-family: 'Epilogue';
    font-size: 20px;
    word-wrap: break-word;
    width: 100%;
    margin-bottom: 25px;
  }
  #header{
    font-family: 'Archivo Black', sans-serif;
    font-size: 34px;
    margin-top: 40px;
    color: #3158E2;
  }
  .tag{
    font-family: 'Epilogue';
    font-size: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
 
  }

  .pictSide{
    margin-left: 30px;
    margin-right: 30px;
    min-width: 80%;
  }
  
  /*about page images formatting*/
  .aboutImg{
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin: 0;
    margin-bottom: 30px;
  }
  .aboutPage{
    flex-direction: column;
  }

  .Links{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }
  
  .insta2Icon{
    width: 45px;
    margin-top: 15px;
    margin-left:0px;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .inprintIcon{
    width: 90px;
    margin-top: 15px;
    margin-left:30px;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  
}

@media only screen and (max-width: 500px) {
  #leftSprite{
    padding-left: 40px;
    height:68px;
    max-width: 100%;
  }
  .tag{
   font-size: 0px;
  }
  #header{
    margin-top: 10px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}